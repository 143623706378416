import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout";
import Seo from "../components/seo";
import Share from "../components/share"

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      post(id: $id) {
        title
        excerpt
        content
        uri
        featuredImage {
          node {
            sourceUrl
          } 
        }
        videoEmbedCode {
          videoEmbedCode
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`

const PostTemplate = ({ data }) => {
  const post = data.wpgraphql.post
  const seoImage = `${post.featuredImage.node.sourceUrl}`
  const url = `${data.site.siteMetadata.url}/blog${post.uri}` 
  return (
    <Layout>
    <div className="postWrapper">
      <Seo 
        title={post.title}
        image={seoImage}
        url={url}
        description={post.excerpt}
      />
      <div className="postWrapper">
        <h1 fontSize="24|null|null|null" dangerouslySetInnerHTML={{ __html: post.title }} />
        <h2 fontSize="18|null|null|null"  style={{marginTop:20, marginBottom:10}}>Please SHARE to pass on this story to a friend or family member:</h2>
        <Share title={post.title} url={url}></Share>
        <div align="center" className="incontent">
          <div align="center" className="dynamic_incontent"></div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <div dangerouslySetInnerHTML={{ __html: `<div class="OUTBRAIN" data-widget-id= "AR_1" data-src = " " data-ob-template="iLoveMyDogSoMuch.tv" ></div>`}} />
        <div className="videoWrapper">
          <div dangerouslySetInnerHTML={{ __html: post.videoEmbedCode.videoEmbedCode }} /> 
        </div>
        <h2 style={{marginTop:30, marginBottom:10}}>Please SHARE to pass on this story to a friend or family member:</h2> 
        <Share title={post.title} url={url}></Share>
        <div align="center" className="incontent">
          <div align="center" className="dynamic_incontent"></div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: `<div class="OUTBRAIN" data-widget-id= "AR_4" data-src = " " data-ob-template="iLoveMyDogSoMuch.tv" ></div>`}} />            
      </div>
    </div> 
    </Layout>
  )
}

export default PostTemplate