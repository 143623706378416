import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {
    FacebookShareButton,
    //FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
  } from 'react-share';

const Share = ({title, url}) => {
    const data = useStaticQuery(graphql`
        query ShareQuery {
            site {
                siteMetadata {
                    defaultTitle: title
                    social {
                        twitter
                    }
                }
            }
        }
    `)
    const {social} = data.site.siteMetadata

    return (
        
        <ul style={{listStyle:"none", margin: "0", padding: "0"}}>
            <li style={{display: "inline-block", verticalAlign: "top", marginRight: "10px"}}>  
                <FacebookShareButton quote={title} url={url}>
                <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
                </div>Share on Facebook</div>                
                </FacebookShareButton>
            </li>

            <li style={{display: "inline-block", marginRight: "10px"}}>
                <TwitterShareButton title={title} via={social.twitter} url={url}>
                    <TwitterIcon size={40} square="true" borderRadius="10"/>
                </TwitterShareButton>
            </li>

            <li style={{display: "inline-block"}}>
                <LinkedinShareButton url={url}>
                    <LinkedinIcon  size={40} square="true" borderRadius="10"/>
                </LinkedinShareButton>
            </li>
        </ul>
            
    );
    
}

export default Share;